import { useLazyQuery, useQuery } from '@apollo/client';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { faLink, faTimes, faUser, faCopy, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Router, useRouter } from 'next/router';
import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Modal, Spinner, UncontrolledAlert } from 'reactstrap';
import { useTextInput } from '../../common/custom-hook';
import { progressBarStore } from '../../context/ProgressBarContext';
import { GENERATE_CUSTOMER_PORTAL, GET_USER_PROFILE } from '../../queries';
import ModalUpdateProfile from './ModalUpdateProfile';

type ModalProfileProps = {
  toggle: Dispatch<SetStateAction<boolean>>;
  isOpen: boolean;
};

const ModalProfile = ({ isOpen, toggle }: ModalProfileProps) => {
  const [isProfileTab, setProfileTab] = useState(true);
  const [userProfile, setUserProfile] = useState<User>();

  const [getUserData, { loading: loadingUserData, data: userData }] = useLazyQuery<{ getUserProfile: User }, never>(
    GET_USER_PROFILE,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: data => {
        setUserProfile(data.getUserProfile);
      },
    }
  );

  useEffect(() => {
    if (isOpen) {
      getUserData();
    }
  }, [isOpen]);

  return (
    <Modal id="modal-profile" isOpen={isOpen} fade={false} toggle={() => toggle(!isOpen)} className="">
      <div className="d-flex justify-content-between">
        <span className="txt-title">Profile Settings</span>
        <span style={{ cursor: 'pointer', color: '#828282' }} onClick={() => toggle(!isOpen)}>
          <FontAwesomeIcon icon={faTimes} width={16} height={16} />
        </span>
      </div>

      <div className="d-flex" style={{ marginTop: 40 }}>
        <div style={{ width: 180 }}>
          <div
            className={`d-flex align-items-center ${isProfileTab ? 'tab-active' : 'tab-default '}`}
            onClick={() => setProfileTab(true)}
          >
            <div className="d-flex align-items-center justify-content-center con-icon-tab">
              <FontAwesomeIcon icon={faUser} width={14} height={14} />
            </div>
            <span className="txt-title-tab">Profile</span>
          </div>

          <div
            className={`d-flex align-items-center ${!isProfileTab ? 'tab-active' : 'tab-default '}`}
            style={{ marginTop: 10 }}
            onClick={() => setProfileTab(false)}
          >
            <div className="d-flex align-items-center justify-content-center con-icon-tab">
              <FontAwesomeIcon icon={faLink} width={14} height={14} />
            </div>
            <span className="txt-title-tab">Invite link/ free credit</span>
          </div>
        </div>

        {isProfileTab ? (
          <ProfileTab userProfile={userProfile} loadingUserData={loadingUserData} />
        ) : (
          <InviteTab userProfile={userProfile} />
        )}
      </div>
    </Modal>
  );
};

type ProfileTabProps = {
  userProfile?: User;
  loadingUserData?: boolean;
};

const ProfileTab = ({ userProfile, loadingUserData }: ProfileTabProps) => {
  const router = useRouter();
  const { pushProgress, completeProgress } = useContext(progressBarStore);
  const [isOpenUpdateProfile, setIsOpenUpdateProfile] = useState(false);
  const [getLinkCustomerPortal, { loading, refetch: refetchLink }] = useLazyQuery(GENERATE_CUSTOMER_PORTAL, {
    notifyOnNetworkStatusChange: false,
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      // console.log(data)
      completeProgress('loadingLinkCustomerPortal');
      router.push(data.generateCustomerPortal);
    },
  });

  useEffect(() => {
    if (loading) pushProgress('loadingLinkCustomerPortal');
  }, [loading]);

  return (
    <div className="con-content-right">
      <div className="d-flex">
        <div className="d-flex flex-column" style={{ flex: 1 }}>
          <span className="txt-title">YOUR NAME</span>
          <span className="txt-content">{userProfile?.full_name}</span>

          <span className="txt-title" style={{ marginTop: 30 }}>
            YOUR EMAIL ADDRESS
          </span>
          <span className="txt-content">{userProfile?.email}</span>

          <span className="txt-title" style={{ marginTop: 30 }}>
            CURRENT PLAN
          </span>

          {loadingUserData ? (
            <div className="loader-custom">Loading...</div>
          ) : (
            <span className="txt-content">{userProfile?.planType?.label}</span>
          )}
        </div>

        <div style={{ width: 150 }} className="d-flex flex-column align-items-center">
          <span>AVATAR</span>

          <img src={userProfile?.avatar} className="img-avatar" />

          <div style={{ marginTop: 10 }}>
            <span className="btn-edit-avatar" style={{ marginRight: 15 }}>
              <FontAwesomeIcon icon={faTrashAlt} width={12} height={12} />
            </span>
            <span className="btn-edit-avatar" >
              <FontAwesomeIcon onClick={() => setIsOpenUpdateProfile(true)} icon={faEdit} width={12} height={12} />
              <ModalUpdateProfile isOpen={isOpenUpdateProfile} toggle={setIsOpenUpdateProfile} />
            </span>
          </div>
        </div>
      </div>

      <div style={{ marginTop: 20 }}>
        <span className="txt-plan" onClick={() => getLinkCustomerPortal()}>
          UPGRADE PLAN
        </span>
        <span className="txt-plan" onClick={() => getLinkCustomerPortal()}>
          PURCHASE CREDIT
        </span>
        <span className="txt-plan" onClick={() => getLinkCustomerPortal()}>
          CUSTOMER PORTAL
        </span>
      </div>
    </div>
  );
};

type InviteTabProps = {
  userProfile?: User;
};

const InviteTab = ({ userProfile }: InviteTabProps) => {

  const [copied, setCopied] = useState(false)
  const projectFullUrl = `${process.env.NEXT_PUBLIC_ORIGIN}/signup?inviteCode=${userProfile?.invite_code}`
  const inputLinkRight = useTextInput(projectFullUrl);

  return (
    <div className="con-content-right">
      <span>Invite your friend and when they spend $15, you will receive 60 mins of free credit.</span>
      <br />
      <br />
      <span>Use the custom invite link below.</span>

      <div className="con-invite-link">
        <div
          className="el-input"
          style={
            copied
              ? { backgroundColor: '#4f206d', transition: 'background 0.2s ease-in-out' }
              : { transition: 'background 0.2s ease-in-out' }
          }
        >
          <input
            id="share-link"
            readOnly={true}
            {...inputLinkRight}
          />
          <span style={{ cursor: 'pointer' }}>
            <CopyToClipboard
              text={inputLinkRight.value}
              onCopy={() => {
                setCopied(true);
                setTimeout(() => {
                  setCopied(false);
                }, 200);
              }}
            >
              <FontAwesomeIcon icon={faCopy} width={16} height={16} />
            </CopyToClipboard>
          </span>
        </div>
      </div>
    </div>
  );
};

export default ModalProfile;
