import React, { useState, useEffect, useRef, MouseEventHandler, Fragment, useCallback, useContext } from 'react';
import { useTextInput, useTextInputSettable } from '../../common/custom-hook';
import { useAuth0 } from '@auth0/auth0-react';
import { User } from '@auth0/auth0-react/src/auth-state';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavbarText,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Popover,
  PopoverBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledPopover,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSpinner,
  faDownload,
  faCheckCircle,
  faTimes,
  faUsers,
  faTimesCircle,
  faBell,
  faClone,
} from '@fortawesome/free-solid-svg-icons';
import TimeCodeSVG from '../svg/TimeCode';
import ModalShare from '../Modal/ModalSharing';
import ModalConfirmfrom from '../Modal/ModalConfirm';
import { KeyBindingUtil } from 'draft-js';
import ModalProfile from '../Modal/ModalProfile';
import { GET_USER_PROFILE } from '../../queries';
import { useLazyQuery, useQuery } from '@apollo/client';
import CustomToolTip from './CustomToolTip';
import Notification from '../Notification/Notification';
import Link from 'next/link';
import { Router, useRouter } from 'next/router';
const { hasCommandModifier } = KeyBindingUtil;
import { CLONE_ASSEMBLY_PROJECT } from '../../queries';
import { useMutation } from '@apollo/client';
import { AlertColorEnum, alertStore } from '../../context/AlertContext';
import ModalPrice from '../Modal/ModalPrice';
import ModalShareSocialMedia from '../Modal/ModalShareSocialMedia';

type AuthButtonProps = {
  user: User;
  isLoading: boolean;
  isAuthenticated: boolean;
  handleLogoutClicked: MouseEventHandler;
  handleLoginClicked: MouseEventHandler;
  handleProfileClicked: MouseEventHandler;
};

const AuthButton = ({
  user,
  isLoading,
  isAuthenticated,
  handleLogoutClicked,
  handleLoginClicked,
  handleProfileClicked,
}: AuthButtonProps) => {
  const [getUserData, { data: userData }] = useLazyQuery<{ getUserProfile: User }, never>(GET_USER_PROFILE);

  useEffect(() => {
    if (isAuthenticated) getUserData();
  }, [isAuthenticated]);

  if (isAuthenticated) {
    return (
      <UncontrolledDropdown nav inNavbar>
        <DropdownToggle nav caret>
          <img src={userData?.getUserProfile?.avatar || user.picture} alt={user.name} className="user-picture" />
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem onClick={handleProfileClicked}>Profile</DropdownItem>
          <DropdownItem onClick={handleLogoutClicked}>Sign out</DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  }
  if (isLoading === false) {
    return (
      <NavItem>
        <Button className="btn btn-simon" onClick={handleLoginClicked}>
          Sign up / Login
        </Button>
      </NavItem>
    );
  }
  return (
    <NavItem>
      <FontAwesomeIcon icon={faSpinner} className="fa-spin" width={16} height={16} />
    </NavItem>
  );
};

type NavigationProps = {
  requestExport?: (fileType: string, extension: string) => void;
  handleSaveClicked?: MouseEventHandler | null;
  hasUnsavedChanges?: boolean;
  isSaving?: boolean;
  saveEnabled?: boolean;
  isReadyForExport?: boolean;
  canOpenShareModal?: boolean;
  saveName?: ((name: string) => void) | null;
  name: string;
  projectid?: string;
  mediaInfo?: {
    height?: number;
    width?: number;
    fps?: string;
    timecode?: string;
  };
  handleAmendAssemblyProject?: (mediaInfo: {
    height?: number;
    width?: number;
    fps?: string;
    timecode?: string;
  }) => void;
  visitors?: Visitor[];
  hasUpdatedPlan?: boolean;
  setHasUpdatedPlan?: (state: any) => void;
};

type exportItem = {
  title: string;
  name: string;
  fileType: string;
  extension: string;
  img: JSX.Element;
  options: [JSX.Element?];
  disable?: boolean;
  beta?: boolean;
  disabledOnProjectStatus?: boolean;
};

type ModalExportProps = {
  isOpen: boolean;
  selectedExport: exportItem | null;
  toggle?: () => void;
  handleDownload?: () => void;
  selectedItem: (item: exportItem) => void;
};

const exportSupported: Array<exportItem> = [
  {
    title: 'Microsoft Word',
    name: 'microsoftWord',
    fileType: 'word',
    extension: 'docx',
    img: <img src="/export-images/microsoft-word.png" />,
    disable: false,
    options: [],
  },
  {
    title: 'Adobe \n Premiere Pro',
    name: 'adobePremiere',
    fileType: 'premiere',
    extension: 'xml',
    img: <img src="/export-images/adobe-premiere.png" />,
    disable: false,
    options: [],
  },
  {
    title: 'Final Cut Pro X',
    name: 'finalCutProX',
    fileType: 'fcpxml',
    extension: 'fcpxml',
    img: <img src="/export-images/final-cut-x.png" />,
    options: [],
  },
  {
    title: 'SRT Subtitles',
    name: 'srt',
    fileType: 'srt',
    extension: 'srt',
    img: <img src="/export-images/srt.png" />,
    options: [],
  },
  {
    title: 'Avid Media\nComposer',
    name: 'avidMediaComposer',
    fileType: 'avid',
    extension: 'edl',
    img: <img src="/export-images/avid-media-composer.png" />,
    options: [],
  },
  {
    title: 'DaVinci Resolve',
    name: 'davinciResolve',
    fileType: 'resolve',
    extension: 'xml',
    img: <img src="/export-images/davinci-resolve.png" />,
    disable: false,
    options: [],
  },
  // {
  //   title: 'Avid Pro Tools',
  //   name: 'avidProTools',
  //   fileType: 'protools',
  //   extension: 'srt',
  //   img: <img src="/export-images/avid-pro-tools.png" />,
  //   disable: true,
  //   options: [],
  // },
  {
    title: 'Adobe Audition',
    name: 'adobeAudition',
    fileType: 'audition',
    extension: 'xml',
    img: <img src="/export-images/adobe-audition.png" />,
    disable: false,
    options: [],
  },
  {
    title: 'Video File',
    name: 'mp4',
    fileType: 'mp4',
    extension: 'mp4',
    img: <img src="/export-images/mp4.png" />,
    disable: false,
    options: [],
    beta: true,
  },
];

const ModalExport = (props: ModalExportProps) => {
  const { isOpen, toggle, handleDownload, selectedExport, selectedItem } = props;

  function isSelectedExport(item: exportItem) {
    if (!selectedExport) return false;
    return item.name === (selectedExport?.name || '');
  }

  return (
    <Modal id="modal-export" isOpen={isOpen} toggle={toggle}>
      <ModalBody>
        <div style={{ textAlign: 'center', position: 'relative' }}>
          <span>Export</span>
          <span
            onClick={toggle}
            style={{
              position: 'absolute',
              right: 0,
              color: '#828282',
              cursor: 'pointer',
            }}
          >
            <FontAwesomeIcon icon={faTimes} width={16} height={16} />
          </span>
        </div>
        <div className="export__container">
          {exportSupported.map((item, index) => (
            <div className={`export__item ${isSelectedExport(item) ? 'active' : ''}`} key={'exportSupported-' + index}>
              <span className="export__item-icon">
                <FontAwesomeIcon icon={faCheckCircle} width={16} height={16} />
              </span>
              <div className="export-type">
                <button
                  className="export__item-image"
                  // disabled={
                  //   activeProject?.status ===
                  //   item.disabledOnProjectStatus
                  // }
                  disabled={item.disable}
                  onClick={() => selectedItem(item)}
                >
                  {item.img}
                </button>
              </div>
              <div className="export__item-title">
                {item.title}
                {item.disable && (
                  <small>
                    <br />
                    Coming soon
                  </small>
                )}
                {item.beta && (
                  <small>
                    <br />
                    Beta
                  </small>
                )}
              </div>
            </div>
          ))}
        </div>
        <div style={{ textAlign: 'center' }}>
          <button className="ss-btn" onClick={handleDownload}>
            <span>Download</span>
            <span style={{ padding: '0 8px' }}>
              <FontAwesomeIcon icon={faDownload} width={16} height={16} />
            </span>
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

const Navigation = ({
  requestExport,
  handleSaveClicked,
  hasUnsavedChanges,
  isSaving,
  saveEnabled,
  isReadyForExport,
  canOpenShareModal,
  saveName,
  name,
  projectid,
  mediaInfo,
  handleAmendAssemblyProject,
  visitors,
  hasUpdatedPlan,
  setHasUpdatedPlan,
}: NavigationProps) => {
  const { showAlert } = useContext(alertStore);
  const [cloneAssemblyProject] = useMutation<
    {
      cloneAssemblyProject: {
        id: string;
      };
    },
    {
      projectId: string;
    }
  >(CLONE_ASSEMBLY_PROJECT, {
    onCompleted: data => {
      router.push({
        pathname: '/[projectid]',
        query: { projectid: data?.cloneAssemblyProject?.id },
      });
    },
    onError: () => {
      showAlert({
        type: AlertColorEnum.danger,
        message:
          'Uh oh. I wasn’t able to clone the project for you. Reach out to support and we’ll get that fixed for you right away!',
      });
    },
  });
  const [isOpenToolTip, setIsOpenToolTip] = useState(false);
  const frameRateOptions = useRef([
    {
      fps: 23.976,
      text: '23.976',
      dropFrame: false,
    },
    {
      fps: 24,
      text: '24',
      dropFrame: false,
    },
    {
      fps: 25,
      text: '25',
      dropFrame: false,
    },
    {
      fps: 29.97,
      text: '29.97DF',
      dropFrame: true,
    },
    {
      fps: 29.97,
      text: '29.97NDF',
      dropFrame: false,
    },
    {
      fps: 30,
      text: '30',
      dropFrame: false,
    },
    {
      fps: 50,
      text: '50',
      dropFrame: false,
    },
    {
      fps: 59.94,
      text: '59.94DF',
      dropFrame: true,
    },
    {
      fps: 59.94,
      text: '59.94NDF',
      dropFrame: false,
    },
    {
      fps: 60,
      text: '60',
      dropFrame: false,
    },
    {
      fps: 0,
      text: '',
      dropFrame: false,
    },
  ]);
  const router = useRouter();
  const { loginWithRedirect, user, isAuthenticated, isLoading, logout } = useAuth0();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenExportModal, setIsOpenExportModal] = useState(false);
  const [isOpenShareModal, setIsOpenShareModal] = useState(false);
  const [isOpenPriceModal, setIsOpenPriceModal] = useState(false);
  const [isOpenProfileModal, setIsOpenProfileModal] = useState(false);
  const [selectedExportItem, setSelectedExportItem] = useState<exportItem | null>(null);
  const [localName, setLocalName] = useState(name);
  const [currentFrameRate, setCurrentFrameRate] = useState({
    fps: 0,
    text: ' ',
    dropFrame: false,
  });
  const [isOpenTimePopover, setIsOpenTimePopover] = useState(false);
  const [isOpenModalConfirmCloningProject, setIsOpenModalConfirmCloningProject] = useState(false);

  const inputHeight = useTextInputSettable(mediaInfo?.height || '', isNumberOrEmpty);
  const inputWidth = useTextInputSettable(mediaInfo?.width || '', isNumberOrEmpty);
  const inputTimecode = useTextInputSettable(mediaInfo?.timecode || '00:00:00:00', isTimeCode);

  const inputHeightRef = useRef<HTMLInputElement>(null);
  const inputWidthRef = useRef<HTMLInputElement>(null);
  const currentRefToolTip = useRef<any>(null);

  function isNumberOrEmpty(event: any) {
    const value = event.target.value;
    return /\d|^$/gi.test(value);
  }

  function isTimeCode(event: any) {
    const value = event.target.value;
    if (currentFrameRate.dropFrame) {
      return /^\d{0,2}:\d{0,2}:[0-5]?[0-9]?;[0-5]?[0-9]?$/.test(value);
    }
    return /^\d{0,2}:\d{0,2}:[0-5]?[0-9]?:[0-5]?[0-9]?$/.test(value);
  }

  useEffect(() => {
    setLocalName(name);
  }, [name]);

  useEffect(() => {
    if (mediaInfo?.fps && mediaInfo?.timecode) {
      const isDropFrame = /^\d{0,2}:\d{0,2}:[0-5]?[0-9]?;[0-5]?[0-9]?$/.test(mediaInfo.timecode);
      const item = frameRateOptions.current.find(
        item => item.dropFrame === isDropFrame && item.fps == Number(mediaInfo!.fps),
      );
      if (item) setCurrentFrameRate(item);
    }
  }, [mediaInfo]);

  useEffect(() => {
    const [hh, mm, ss, ff] = inputTimecode.value.split(/:|;/);

    if (currentFrameRate.dropFrame) {
      inputTimecode.setvalue(`${hh}:${mm}:${ss};${ff}`);
    } else {
      inputTimecode.setvalue(`${hh}:${mm}:${ss}:${ff}`);
    }
  }, [currentFrameRate, inputTimecode]);

  useEffect(() => {
    // @ts-ignore
    function handleKeydown(e) {
      if (e.keyCode === 69 /* `E` key */ && hasCommandModifier(e)) {
        e.preventDefault();
        setIsOpenExportModal(prevState => !prevState);
      }
    }

    // @ts-ignore
    document.body.addEventListener('keydown', handleKeydown, false);

    return () => {
      // @ts-ignore
      document.body.removeEventListener('keydown', handleKeydown);
    };
  }, []);

  const editableName = useRef<HTMLInputElement>(null);

  const toggle = () => setIsOpen(!isOpen);

  const handleExportClicked = async () => {
    if (!requestExport) {
      return;
    }
    setIsOpenExportModal(true);
  };

  const handleConfirmAmendMediaInfo = useCallback(() => {
    if (!handleAmendAssemblyProject) return;

    const height = +inputHeight.value;
    const width = +inputWidth.value;
    let timecode = inputTimecode.value;
    const fps = currentFrameRate.fps + '';
    let [hh, mm, ss, ff] = timecode.trim().split(/:|;/);

    function normalizeTimeNumber(timeStr: string) {
      if (!timeStr) return '00';

      const num = Number(timeStr);
      if (!num) return '00';
      return num < 10 ? '0' + num : num + '';
    }

    hh = normalizeTimeNumber(hh);
    mm = normalizeTimeNumber(mm);
    ss = normalizeTimeNumber(ss);
    ff = normalizeTimeNumber(ff);

    timecode = `${hh}:${mm}:${ss}${currentFrameRate.dropFrame ? ';' : ':'}${ff}`;

    handleAmendAssemblyProject({
      height,
      width,
      timecode,
      fps,
    });
    setIsOpenTimePopover(prev => !prev);
  }, [inputHeight, inputWidth, inputTimecode, currentFrameRate, handleAmendAssemblyProject]);

  function handleCancelAmendMediaInfo() {
    inputHeight.setvalue(mediaInfo?.height || '');
    inputWidth.setvalue(mediaInfo?.width || '');
    inputTimecode.setvalue(mediaInfo?.timecode || '00:00:00:00');

    if (mediaInfo?.fps && mediaInfo?.timecode) {
      const isDropFrame = /^\d{0,2}:\d{0,2}:[0-5]?[0-9]?;[0-5]?[0-9]?$/.test(mediaInfo.timecode);
      const item = frameRateOptions.current.find(
        item => item.dropFrame === isDropFrame && item.fps == Number(mediaInfo!.fps),
      );
      if (item) setCurrentFrameRate(item);
    } else {
      setCurrentFrameRate({
        fps: 0,
        text: ' ',
        dropFrame: false,
      });
    }

    setIsOpenTimePopover(!isOpenTimePopover);
  }

  useEffect(() => {
    let height = +inputHeight.value;
    let width = +inputWidth.value;
    if (height % 2 !== 0 && height !== 0) {
      currentRefToolTip.current = inputHeightRef.current;
      setIsOpenToolTip(true);
    } else if (width % 2 !== 0 && width !== 0) {
      currentRefToolTip.current = inputWidthRef.current;
      setIsOpenToolTip(true);
    } else {
      height = 0;
      width = 0;
      setIsOpenToolTip(false);
      currentRefToolTip.current = null;
    }
  }, [inputHeight, inputWidth]);

  useEffect(() => {
    const TIME_OUT = 5000;
    let currentTime = 0;
    let newDIV: HTMLDivElement | null = null;

    const job = setInterval(() => {
      if (!isOpenTimePopover || currentTime >= TIME_OUT) {
        clearInterval(job);
        return;
      }

      const popoverEl = document.querySelector('div.fade.show .timecode-popover')?.parentNode;
      if (popoverEl) {
        clearInterval(job);
        newDIV = document.createElement('div');
        newDIV.classList.add('timecode-popover__overlay');
        newDIV.onclick = handleConfirmAmendMediaInfo;
        popoverEl.prepend(newDIV);
      }

      currentTime += 300;
    }, 300);

    return () => {
      if (newDIV !== null) newDIV.remove();
      clearInterval(job);
    };
  }, [isOpenTimePopover, handleConfirmAmendMediaInfo]);

  function cloneProject() {
    if (projectid) cloneAssemblyProject({ variables: { projectId: projectid } });
    setIsOpenModalConfirmCloningProject(prev => !prev);
  }

  return (
    <div id="navigation">
      <Navbar fixed="top" dark color="dark" expand="md">
        <NavbarBrand href="/">
          <img src="/simonsays-logo.png" className="logo" alt="Simon Says" />
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto" navbar>
            {!requestExport && (
              <NavItem style={{ width: '200px' }}>
                <a href="https://app.simonsays.ai/app" target="_blank">
                  <Button className="btn btn-simon">Transcription Mode</Button>
                </a>
              </NavItem>
            )}
            <NavItem>
              <NavbarText>
                <input
                  ref={editableName}
                  value={localName}
                  onChange={e => setLocalName(e.target.value)}
                  onBlur={e => {
                    if (saveName) {
                      const newName = e.target.value.trim() || 'Untitled Project';
                      setLocalName(newName);
                      editableName.current?.setSelectionRange(0, 0);
                      saveName(newName);
                    }
                  }}
                  onKeyPress={e => {
                    if (e.key === 'Enter') {
                      editableName.current?.blur();
                    }
                  }}
                  className="invisible-input"
                  style={{ width: '400px' }}
                  disabled={!saveName}
                />
              </NavbarText>
            </NavItem>
            <NavItem style={{ width: '100px' }}>
              {isSaving && <FontAwesomeIcon icon={faSpinner} className="fa-spin" width={16} height={16} />}
              {handleSaveClicked && hasUnsavedChanges && !isSaving && saveEnabled && (
                <Button onClick={handleSaveClicked} color="link" size="sm" className="save-now">
                  Save now
                </Button>
              )}
            </NavItem>
          </Nav>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <button className="btn-upgrade" onClick={() => setIsOpenPriceModal(prevState => !prevState)}>
                Upgrade
              </button>
            </NavItem>
            <NavItem>
              <div className="visitors">
                {visitors &&
                  visitors.length > 0 &&
                  visitors.slice(0, 4).map((visitor, index) => (
                    <div
                      key={'visitor-' + index}
                      className="visitors__avatar"
                      style={{ zIndex: index }}
                      title={visitor.full_name}
                    >
                      <img src={visitor.avatar} alt={visitor.full_name} />
                    </div>
                  ))}
                {visitors && visitors.length > 0 && visitors.length >= 5 && (
                  <React.Fragment>
                    <div
                      id="PopoverClick"
                      className="visitors__avatar-holder"
                      title={`${visitors.length - 4} additional other viewer${visitors.length === 5 ? '' : 's'}`}
                    >
                      <div>{visitors.length - 4 < 9 ? visitors.length - 4 : '9+'}</div>
                    </div>
                    <UncontrolledPopover trigger="click" placement="bottom" target="PopoverClick">
                      <PopoverBody>
                        <div className="visitors__popover">
                          <span>{visitors.length} total viewers</span>
                          {visitors.map((visitor, index) => (
                            <React.Fragment key={'visitor-' + index}>
                              <div className="visitor-popover">
                                <div className="visitor-popover__avatar">
                                  <img src={visitor.avatar} alt={visitor.full_name} title={visitor.full_name} />
                                </div>
                                <div
                                  className="visitor-popover__fullname"
                                  style={{ marginLeft: 8 }}
                                  title={visitor.full_name}
                                >
                                  {visitor.full_name}
                                </div>
                              </div>
                            </React.Fragment>
                          ))}
                        </div>
                      </PopoverBody>
                    </UncontrolledPopover>
                  </React.Fragment>
                )}
              </div>
            </NavItem>
            {isAuthenticated && projectid && (
              <NavItem>
                <ModalShareSocialMedia projectid={projectid} />
              </NavItem>
            )}
            {isAuthenticated && (
              <NavItem>
                <Notification />
              </NavItem>
            )}

            {canOpenShareModal && (
              <NavItem>
                <span style={{ cursor: 'pointer' }} onClick={() => setIsOpenShareModal(prevState => !prevState)}>
                  <FontAwesomeIcon icon={faUsers} width={16} height={16} />
                </span>
              </NavItem>
            )}
            {canOpenShareModal && (
              <NavItem>
                <span id="timecode-popover" style={{ cursor: 'pointer' }}>
                  <span onClick={() => setIsOpenTimePopover(prev => !prev)}>
                    <TimeCodeSVG />
                  </span>
                  <Popover
                    popperClassName="timecode-popover"
                    isOpen={isOpenTimePopover}
                    placement="bottom"
                    target="timecode-popover"
                  >
                    <CustomToolTip isOpen={isOpenToolTip} element={currentRefToolTip} placement="bottom" />
                    <PopoverBody className="timecode-popover__body">
                      <div style={{ padding: '8px 0' }}>
                        <div className="timecode-popover__label">Frame Rate</div>
                        <UncontrolledDropdown>
                          <DropdownToggle caret>
                            <span style={{ width: 70, display: 'inline-block', textAlign: 'left' }}>
                              {currentFrameRate.text}
                            </span>
                          </DropdownToggle>
                          <DropdownMenu>
                            {frameRateOptions.current.map((option, index) => (
                              <DropdownItem onClick={() => setCurrentFrameRate(option)} key={option.text + '-' + index}>
                                {option.text}
                              </DropdownItem>
                            ))}
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                      <div style={{ padding: '8px 0' }}>
                        <div className="timecode-popover__label">Resolution</div>
                        <div className="timecode-popover__resolution">
                          <div style={{ marginRight: 4 }}>
                            <label>Width</label>
                            <input
                              value={inputWidth.value}
                              onChange={inputWidth.onChange}
                              ref={inputWidthRef}
                              className={isOpenToolTip ? 'input-error' : ''}
                            />
                          </div>
                          <div>
                            <label>Height</label>
                            <input
                              value={inputHeight.value}
                              onChange={inputHeight.onChange}
                              ref={inputHeightRef}
                              className={isOpenToolTip ? 'input-error' : ''}
                            />
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="timecode-popover__label">Start Timecode</div>
                        <input value={inputTimecode.value} onChange={inputTimecode.onChange} />
                      </div>
                      <div style={{ textAlign: 'right', marginTop: 8 }}>
                        <span
                          className={`timecode-popover__btn-confirm ` + `${isOpenToolTip ? 'btn-disable' : ''}`}
                          onClick={handleConfirmAmendMediaInfo}
                        >
                          <FontAwesomeIcon icon={faCheckCircle} width={16} height={16} />
                        </span>
                        <span className="timecode-popover__btn-cancel" onClick={handleCancelAmendMediaInfo}>
                          <FontAwesomeIcon icon={faTimesCircle} width={16} height={16} />
                        </span>
                      </div>
                    </PopoverBody>
                  </Popover>
                </span>
              </NavItem>
            )}
            {canOpenShareModal && (
              <NavItem>
                <span
                  title="Clone Project"
                  style={{ cursor: 'pointer' }}
                  onClick={() => setIsOpenModalConfirmCloningProject(prevState => !prevState)}
                >
                  <FontAwesomeIcon icon={faClone} width={16} height={16} />
                </span>
              </NavItem>
            )}

            <NavItem>
              {requestExport && (
                <Fragment>
                  <Button
                    id="exportButton"
                    className="btn btn-simon"
                    onClick={handleExportClicked}
                    disabled={!isReadyForExport}
                  >
                    Export Project
                  </Button>
                </Fragment>
              )}
            </NavItem>
            <AuthButton
              user={user}
              isLoading={isLoading}
              isAuthenticated={isAuthenticated}
              handleLoginClicked={() => loginWithRedirect()}
              handleLogoutClicked={() => {
                localStorage.removeItem('ss.assemble.hasAuthenticated');
                localStorage.removeItem('invite_code');
                logout({ returnTo: process.env.NEXT_PUBLIC_ORIGIN });
              }}
              handleProfileClicked={() => setIsOpenProfileModal(prevState => !prevState)}
            />
          </Nav>
        </Collapse>
      </Navbar>
      <ModalExport
        isOpen={isOpenExportModal}
        toggle={() => setIsOpenExportModal(prevState => !prevState)}
        selectedItem={setSelectedExportItem}
        selectedExport={selectedExportItem}
        handleDownload={() => {
          if (requestExport && selectedExportItem) {
            requestExport(selectedExportItem.fileType, selectedExportItem.extension);
          }
        }}
      />
      {projectid && <ModalShare isOpen={isOpenShareModal} toggle={setIsOpenShareModal} projectid={projectid} />}

      <ModalConfirmfrom
        isOpen={isOpenModalConfirmCloningProject}
        toggle={() => setIsOpenModalConfirmCloningProject(prev => !prev)}
        cancel={() => setIsOpenModalConfirmCloningProject(false)}
        confirm={() => cloneProject()}
        title="Clone the current project?"
        confirmText="Clone"
        cancelText="Cancel"
        swapActive
      />

      <ModalProfile isOpen={isOpenProfileModal} toggle={setIsOpenProfileModal} />

      <ModalPrice isOpen={isOpenPriceModal} toggle={setIsOpenPriceModal} />
    </div>
  );
};

export default Navigation;
